import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Alert, Box, Tab, Tabs } from "@mui/material";
import { QueryStatus } from "@tanstack/react-query";
import { Spacer } from "@tbml/components/Spacer";
import { useSkeleton } from "@tbml/shared-dependencies/react-skeletons";
import { H3, H4, Text } from "@tbml/components/Typography";
import {
  SocialMediaPlatform,
  socialMediaPlatforms,
  SocialMediaPlatforms,
} from "@tbml/api-interface/socialMedia";
import {
  getBrandColorByPlatform,
  SocialMediaIcon,
} from "@tbml/components/SocialMediaPlatform/SocialMediaIcon";
import tokens from "@tbml/tokens";
import useScrollSection from "@tbml/components/ScrollSections/useScrollSection";
import {
  SocialMediaArticle,
  SOCIAL_MEDIA_MIN_WIDTH,
  InstagramPostDynamic,
} from "@tbml/components/SocialMediaPlatform";
import { Article } from "@tbml/hooks/useArticles";
import { CategoryConsentId } from "../../lib/iubenda";
import { ArticlesSlider } from "../ArticlesSlider";
import { SocialMediaSliderPlaceholder } from "./SocialMediaSliderPlaceholder";
import { LiteBadge, SectionText, SocialMediaContainer } from "./styles";
import { fixInstagramEmbeddedLink } from "./utils";

type PlatformKey = keyof SocialMediaPlatforms;
type Platform = {
  key: PlatformKey;
  platform: SocialMediaPlatform;
};

export type SocialMediaSectionProps = {
  articles: Article[] | undefined;
  articleStatus: QueryStatus;
  initialPlatform?: PlatformKey;
  isStory?: boolean;
  showFirefoxWarning?: boolean;
  showMissingCookieConsent?: boolean;
};

const getInitialPlatform = (
  initialPlatformSetting: PlatformKey,
  platforms: Platform[]
) => {
  if (platforms.length === 0) return initialPlatformSetting;
  if (!platforms.find((platform) => platform.key === initialPlatformSetting))
    return platforms[0].key;
  return initialPlatformSetting;
};

const RANDOM_INSTAGRAM_POST = "https://www.instagram.com/p/DAAQqFkqFam/";
function InstagramFailedLoadHackFix({
  hasInstagram,
}: {
  hasInstagram: boolean;
}) {
  if (!hasInstagram) return null;
  return (
    <div style={{ display: "none" }}>
      <InstagramPostDynamic deeplink={RANDOM_INSTAGRAM_POST} loading={false} />
    </div>
  );
}

export function SocialMediaSection({
  articles,
  articleStatus,
  initialPlatform = "linkedIn",
  isStory = false,
  showFirefoxWarning = false,
  showMissingCookieConsent = false,
  ...props
}: SocialMediaSectionProps): JSX.Element | null {
  const platforms = useMemo(
    () =>
      Object.entries(socialMediaPlatforms)
        .map(([key, platform]) => ({
          key: key as PlatformKey,
          platform,
          count:
            articles?.filter(
              ({ mediaId }) => mediaId && platform.mediaIds.includes(mediaId)
            ).length ?? 0,
        }))
        .filter(({ count }) => count > 0),
    [articles]
  );
  const [selectedPlatformKey, setSelectedPlatformKey] = useState<
    keyof SocialMediaPlatforms
  >(getInitialPlatform(initialPlatform, platforms));
  const isFirefox =
    navigator.userAgent.indexOf("Firefox") !== -1 || showFirefoxWarning;

  const isSocialMediaConsentGiven: boolean = useMemo(() => {
    if (showMissingCookieConsent) return false;
    // eslint-disable-next-line no-underscore-dangle
    return !!window?._iub?.cs?.consent?.purposes?.[
      CategoryConsentId.Experience
    ];
  }, [showMissingCookieConsent]);

  const Skeleton = useCallback(
    () => (
      <SocialMediaSliderPlaceholder
        platform={socialMediaPlatforms[selectedPlatformKey]}
        count={6}
        isSocialMediaConsentGiven={isSocialMediaConsentGiven}
      />
    ),
    [isSocialMediaConsentGiven, selectedPlatformKey]
  );

  const [initialIndex, setInitialIndex] = useState(0);
  const [tabIndex, setTabIndex] = useState(0);

  const { withSkeleton } = useSkeleton({
    Skeleton,
    isLoading: articleStatus === "pending" || !isSocialMediaConsentGiven,
  });

  useEffect(() => {
    if (
      !platforms.find((platform) => platform.key === initialPlatform) &&
      platforms.length > 0
    )
      setSelectedPlatformKey(platforms[0].key as keyof SocialMediaPlatforms);
  }, [initialPlatform, platforms]);

  const scrollSectionProps = useScrollSection();

  const groupedArticles: Article[][] = [];
  const mediaIdsIndexes = new Map<number, { index: number; key: string }>();
  const groupPlatformMapping = new Map<number, string>();

  let index = 0;
  for (const platform of platforms) {
    groupedArticles.push([]);
    const { mediaIds } = platform.platform;
    for (const mediaId of mediaIds) {
      mediaIdsIndexes.set(mediaId, { index, key: platform.key });
    }
    groupPlatformMapping.set(index, platform.key);
    index += 1;
  }

  const inputArticles = articles ?? [];

  for (const article of inputArticles) {
    if (article.mediaId) {
      const sectionIndex = mediaIdsIndexes.get(article.mediaId);
      if (sectionIndex === undefined) {
        throw new Error("Couldn't find sort section in the map.");
      }
      groupedArticles[sectionIndex.index].push(article);
    }
  }

  const hasInstagram = useMemo(
    () => !!platforms.find(({ key }) => key === "instagram"),
    [platforms]
  );

  if (articleStatus === "success" && platforms.length === 0) {
    return null;
  }

  return (
    <Box {...props} {...scrollSectionProps}>
      {isStory ? (
        <H4>Social media coverage of this story</H4>
      ) : (
        <H3>Social Media</H3>
      )}
      <Spacer size="gutterS" />
      <InstagramFailedLoadHackFix hasInstagram={hasInstagram} />
      {withSkeleton(
        <>
          <Tabs
            value={selectedPlatformKey}
            onChange={(_, newValue) => {
              const newTabIndex = platforms.findIndex(
                (p) => p.key === newValue
              );
              setTabIndex(newTabIndex);
              let newInitialIndex = 0;
              for (let k = 0; k < groupedArticles.length; k += 1) {
                const group = groupedArticles[k];
                if (k !== newTabIndex) {
                  newInitialIndex += group.length;
                } else {
                  break;
                }
              }
              setInitialIndex(newInitialIndex);
              setSelectedPlatformKey(newValue);
            }}
            aria-label="Social Media sections"
            variant="scrollable"
            color={getBrandColorByPlatform(
              socialMediaPlatforms[selectedPlatformKey]
            )}
            scrollButtons="auto"
            allowScrollButtonsMobile
          >
            {platforms.map(({ key, platform, count }) => (
              <Tab
                key={`${key}-section`}
                label={
                  <SectionText>
                    <SocialMediaIcon
                      size="small"
                      platform={platform}
                      color={
                        selectedPlatformKey !== key
                          ? tokens.color.textSecondary.value
                          : undefined
                      }
                    />
                    <Text color="inherit">{platform.label}</Text>
                    <LiteBadge
                      aria-label={`Item count for platform ${platform.label}`}
                    >
                      {count}
                    </LiteBadge>
                  </SectionText>
                }
                value={key}
              />
            ))}
          </Tabs>
          {selectedPlatformKey === "linkedIn" && isFirefox ? (
            <>
              <Spacer size="paddingXs" />
              <Alert
                severity="warning"
                aria-label="Incompatible with Firefox for LinkedIn Posts"
              >
                Due to restrictions from LinkedIn side, no images are displayed
                for LinkedIn posts in Firefox.
              </Alert>
              <Spacer size="paddingXs" />
            </>
          ) : (
            <Spacer size="gutterM" />
          )}
          <SocialMediaContainer
            aria-label="Social Media Container"
            sx={{ position: "relative" }}
          >
            <ArticlesSlider
              initialIndex={initialIndex}
              centerNavigation={false}
              onIndexChange={(itemIndex) => {
                let groupIndex = 0;
                let articleIndex = 0;
                const map = new Map<number, number>();
                groupedArticles.forEach((group) => {
                  group.forEach(() => {
                    map.set(articleIndex, groupIndex);
                    articleIndex += 1;
                  });
                  groupIndex += 1;
                });

                const articleGroup = map.get(itemIndex);
                if (articleGroup === undefined) {
                  return;
                }
                const newTab = platforms[articleGroup]
                  .key as keyof SocialMediaPlatforms;
                if (articleGroup < tabIndex) {
                  setTabIndex(articleGroup);
                  setSelectedPlatformKey(newTab);
                } else if (articleGroup > tabIndex) {
                  setTabIndex(articleGroup);
                  setSelectedPlatformKey(newTab);
                }
              }}
              itemWidth={SOCIAL_MEDIA_MIN_WIDTH}
              groupedItems={groupedArticles}
              renderItem={(
                { mediaId, id, deeplink, oEmbed },
                idx,
                lastLoaded
              ) =>
                mediaId && (deeplink || oEmbed) ? (
                  <SocialMediaArticle
                    key={id}
                    mediaId={mediaId}
                    url={fixInstagramEmbeddedLink(deeplink) ?? undefined}
                    embedCode={oEmbed?.html}
                    loading={idx > lastLoaded}
                  />
                ) : (
                  <div />
                )
              }
            />
          </SocialMediaContainer>
        </>
      )}
    </Box>
  );
}
